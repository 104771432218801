import { Dispatch, createContext, useContext, useReducer } from "react";
import { ChecklistsListState } from "../store/checklistsListReducer.state";
import { ChecklistsListsAction } from "../store/checklistsListReducer.actions";
import { checklistsListReducer, createChecklistsListState } from "../store/checklistsListReducer";

export const UserChecklistsContext = createContext<ChecklistsListState | null>(
  null
);
export const UserChecklistsDispatchContext =
  createContext<Dispatch<ChecklistsListsAction> | null>(null);

export function ChecklistCountProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(
    checklistsListReducer,
    createChecklistsListState(),
  );

  return (
    <UserChecklistsContext.Provider value={state}>
      <UserChecklistsDispatchContext.Provider value={dispatch}>
        {children}
      </UserChecklistsDispatchContext.Provider>
    </UserChecklistsContext.Provider>
  );
}

export function useUserChecklists() {
  const state = useContext(UserChecklistsContext);
  if (!state) {
    throw new Error(
      "useChecklistCounts must be used within a ChecklistCountsContext"
    );
  }
  return state;
}

export function useUserChecklistsDispatch() {
  const dispatch = useContext(UserChecklistsDispatchContext);
  if (!dispatch) {
    throw new Error(
      "useChecklistCountsDispatch must be used within a ChecklistCountsDispatchContext"
    );
  }
  return dispatch;
}
