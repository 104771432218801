import { LegoPartModel, LegoPartCountModel } from "../../../models";
import { isGroupNameMatches } from "../lib/filter-helpers";
import { LegoPartFilterValue } from "../models";
import {
  ChecklistCountState,
  ChecklistGroupModel,
  LegoPartChecklistModel,
} from "./checklistReducer.state";

export enum PartTitle {
  Name,
  Color,
}

export function mapToGroupItem(
  groupName: string,
  groupParts: LegoPartModel[],
  partTitle: PartTitle,
  state: Pick<
    ChecklistCountState,
    "expandedState" | "completedVisible" | "filterValue"
  >
): ChecklistGroupModel {
  const parts = groupParts.map((part): LegoPartChecklistModel => {
    let title = "";
    switch (partTitle) {
      case PartTitle.Name:
        title = part.partName;
        break;
      case PartTitle.Color:
        title = part.color;
        break;
    }

    return {
      ...part,
      title: title,
      isVisible: isPartVisible(part, null, state.completedVisible),
    };
  });

  const group: ChecklistGroupModel = {
    groupName: groupName,
    parts: parts,
    counts: [],
    isExpanded: state.expandedState,
    isVisible: isGroupVisible(
      {
        groupName,
        parts: parts,
        counts: [],
      },
      state.filterValue,
      state.completedVisible
    ),
  };

  return group;
}

export function mapPartsListVisibility(
  item: Pick<ChecklistGroupModel, "counts">,
  parts: LegoPartChecklistModel[],
  completedVisible: boolean
): LegoPartChecklistModel[] {
  return parts.map((part) => mapPartVisibility(item, part, completedVisible));
}

export function mapPartVisibility(
  item: Pick<ChecklistGroupModel, "counts">,
  part: LegoPartChecklistModel,
  completedVisible: boolean
): LegoPartChecklistModel {
  const c = item.counts.find((x) => x.invPartId === part.invPartId)?.count ?? 0;
  return {
    ...part,
    isVisible: isPartVisible(part, c, completedVisible),
  };
}

export function isPartVisible(
  part:
    | Pick<LegoPartChecklistModel, "quantity">
    | Pick<LegoPartModel, "quantity">,
  count: number | null,
  completedVisible: boolean
): boolean {
  if (count === null) {
    return true;
  }

  const isCompleted = +count === +part.quantity;
  return completedVisible || !isCompleted;
}

export type GroupItem = Pick<ChecklistGroupModel, "groupName" | "counts"> & {
  parts: Pick<LegoPartModel, "invPartId" | "partName" | "quantity">[];
};

export function isGroupVisible(
  item: GroupItem,
  filter: LegoPartFilterValue | null,
  completedVisible: boolean
): boolean {
  // TODO: consider if list is grouped by name or part color
  const isMatchesFilter = !filter || isGroupNameMatches(item.groupName, filter);
  if (!isMatchesFilter) {
    return false;
  }

  const totalCounts = item.counts.reduce((acc, count) => acc + count.count, 0);
  const totalQuantity = item.parts.reduce(
    (acc, part) => acc + part.quantity,
    0
  );
  const isCompleted = totalCounts === totalQuantity;

  if (isCompleted && !completedVisible) {
    return false;
  }

  return true;
}

export function isNewerDate(
  a: Date | string | null,
  b: Date | string | null
): boolean {
  if ((a === null || a === undefined) && b) {
    return false;
  }

  if ((b === null || b === undefined) && a) {
    return true;
  }

  if (!a || !b) {
    return false;
  }

  const aTime = new Date(a).getTime();
  const bTime = new Date(b).getTime();

  return aTime > bTime;
}

export function canUpdatePartCount(
  current: LegoPartCountModel | undefined,
  incoming: LegoPartCountModel
): boolean {
  if (!incoming || incoming.count === null || incoming.count === undefined) {
    return false;
  }

  if (!current) {
    return true;
  }

  if (current.updated && incoming.updated) {
    if (isNewerDate(current.updated, incoming.updated)) {
      return false;
    }
  }

  return current.count !== incoming.count;
}

export function incrementPartCount(
  currentCount: number | null,
  partQuantity: number
): number {
  if (currentCount === null || currentCount === undefined) {
    return 1;
  } else if (currentCount < partQuantity) {
    return currentCount + 1;
  } else {
    return currentCount;
  }
}

export function decrementPartCount(
  currentCount: number | null,
  partQuantity: number
): number {
  if (
    currentCount === 0 ||
    currentCount === null ||
    currentCount === undefined
  ) {
    return partQuantity;
  } else if (currentCount > 0) {
    return currentCount - 1;
  }

  return currentCount;
}
