import Filter from "./Filter";
import "./header.css";
import { LegoPartFilterValue } from "../../models";
import {
  useChecklistCounts,
  useChecklistCountsDispatch,
} from "../../contexts/ChecklistContext";
import { useEffect, useState } from "react";

export default function Header() {
  const dispatch = useChecklistCountsDispatch();
  const { completedVisible, expandedState, filterValue } = useChecklistCounts();
  const [filterText, setFilterText ] = useState<string | null>(null);

  function toggleGroups() {
    if (expandedState) {
      dispatch({ type: "collapse-all" });
    } else {
      dispatch({ type: "expand-all" });
    }
  }

  function onClickHideCompleted() {
    dispatch({ type: "hide-completed" });
  }

  function onClickShowCompleted() {
    dispatch({ type: "show-completed" });
  }

  function findDialog(): HTMLDialogElement {
    return document.getElementById("filterDialog") as HTMLDialogElement
  }

  function handleFilter(value: LegoPartFilterValue) {
    dispatch({ type: "filter", filter: value });
    findDialog()?.close();
  }

  function openFilter() {
    findDialog().showModal();
  }

  useEffect(() => {
    if(!isFilterEmpty(filterValue)) {
      setFilterText(`Filter active`);
    } else {
      setFilterText(null);
    }
  }, [filterValue]);

  return (
    <>
      <div className="site-header">
        <div className="toggle-completed">
          <div>Completed parts:</div>
          <div className="buttons">
            <button
              className={(completedVisible ? "" : " selected")}
              onClick={onClickHideCompleted}
            >
              Hide
            </button>
            <button
              className={(completedVisible ? " selected" : "")}
              onClick={onClickShowCompleted}
            >
              Show
            </button>
          </div>
        </div>
        <div className="filter">
          <button onClick={openFilter} className={filterText ? "selected" : ""}>
            Filtering
          </button>
          {filterText && <div className="filter-content">{filterText}</div>}
        </div>
        <button className="toggle" onClick={toggleGroups}>
          {expandedState ? "Collapse" : "Expand"}
        </button>
      </div>
      <dialog id="filterDialog">
        <Filter onFilter={handleFilter}></Filter>
      </dialog>
    </>
  );
}

function isFilterEmpty(value: LegoPartFilterValue | null): boolean {
  if(value && (value.name !== "" || value.text !== "" || value.w !== 0 || value.l !== 0 || value.h !== 0)) {
    return false;
  } else {
    return true;
  }
}