import { useEffect, useState } from "react";

export const useFormInput = (
  initialValue: string,
  validationFn: (value: string) => boolean
) => {
  const [value, setValue] = useState<string>(initialValue);
  const [valid, setValid] = useState<boolean>(true);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (!touched) return;
    setValid(validationFn(value));
  }, [value, validationFn, touched]);

  function newValue(value: string) {
    setTouched(true);
    setValue(value);
  }

  return { value, setValue: newValue, valid, touched };
};
