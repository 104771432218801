import { useEffect } from "react";
import { CoverModel, Status } from "../models";
import { ChecklistsListsAction } from "../store/checklistsListReducer.actions";
import { ChecklistItemModel } from "../store/checklistsListReducer.state";
import { ChecklistStorage } from "../../../lib/checklistsStorage";
import { createSetDetailsAPI } from "../../../lib/apiSet";

export const useSetDetailsLoading = (
  items: ChecklistItemModel[],
  dispatch: React.Dispatch<ChecklistsListsAction>
) => {
  useEffect(() => {
    const setsToLoad = items.filter(
      (item) => item.detailsStatus === Status.None
    );
    if (setsToLoad.length === 0) {
      return;
    }

    const storage = new ChecklistStorage();
    setsToLoad.forEach(({ setNumber }) => {
      dispatch({ type: "set-loading", numbers: [setNumber] });

      const localSetInfo: CoverModel | null = storage.loadSetInfo(setNumber);
      if (localSetInfo) {
        dispatch({ type: "set-loaded", details: [localSetInfo] });
        return;
      }

      const api = createSetDetailsAPI();
      api
        .loadSetCover(setNumber)
        .then((data: CoverModel) => {
          if (!data) {
            dispatch({ type: "set-loading-failed", numbers: [setNumber] });
          } else {
            storage.saveSetInfo(setNumber, data);
            dispatch({ type: "set-loaded", details: [data] });
          }
        })
        .catch(() => {
          dispatch({ type: "set-loading-failed", numbers: [setNumber] });
        });
    });
  }, [items, dispatch]);
};
