import { LegoPartModel } from "../../../models";

export function groupListByPartName(details: LegoPartModel[]): PartsByPartName {
  details.sort((a: LegoPartModel, b: LegoPartModel) =>
    a.partName.localeCompare(b.partName)
  );

  const groups = details.reduce((acc: PartsByPartName, item: LegoPartModel) => {
    const groupName: string =
      item.partName[0] + item.partName.substring(1).toLowerCase();

    if (!acc[groupName]) {
      acc[groupName] = [];
    }

    acc[groupName].push({
      ...item,
      invPartId: +item.invPartId,
      quantity: +item.quantity,
    });

    return acc;
  }, {} as PartsByPartName);

  return groups;
}

export type PartsByPartName = {
  [key: string]: LegoPartModel[];
};
