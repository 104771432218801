import { LegoPartCountModel } from "../../../models";
import { groupListByColorName } from "../lib/groupByColorName";
import { groupListByPartName } from "../lib/groupByName";
import { ChecklistAction } from "./checklistReducer.actions";
import {
  mapToGroupItem,
  PartTitle,
  incrementPartCount,
  decrementPartCount,
  canUpdatePartCount,
  mapPartsListVisibility,
  isGroupVisible,
  isNewerDate,
} from "./checklistReducer.helpers";
import {
  ChecklistCountState,
  ChecklistGroupModel,
} from "./checklistReducer.state";

export function checklistReducer(
  state: ChecklistCountState,
  action: ChecklistAction
): ChecklistCountState {
  switch (action.type) {
    case "counts-loaded": {
      return {
        ...state,
        groups: state.groups.map((group) => {
          const groupCounts = action.counts.reduce<LegoPartCountModel[]>(
            (acc, item: LegoPartCountModel) => {
              if (group.parts.some((x) => +x.invPartId === +item.invPartId)) {
                acc.push({
                  ...item,
                  invPartId: +item.invPartId,
                  count: +item.count,
                });
              }
              return acc;
            },
            []
          );

          if (groupCounts.length === 0) {
            return group;
          }

          return {
            ...group,
            counts: groupCounts,
          };
        }),
      };
    }
    case "group-by-part-name": {
      const grouped = groupListByPartName(action.parts);
      const list: ChecklistGroupModel[] = [];

      Object.keys(grouped).forEach((groupName) => {
        const groupParts = grouped[groupName];
        const group = mapToGroupItem(
          groupName,
          groupParts,
          PartTitle.Color,
          state
        );
        list.push(group);
      });

      return {
        ...state,
        groups: list,
      };
    }
    case "group-by-color-name": {
      const grouped = groupListByColorName(action.parts);
      const list: ChecklistGroupModel[] = [];

      Object.keys(grouped).forEach((groupName) => {
        const groupParts = grouped[groupName];
        groupParts.sort((a, b) => a.partName.localeCompare(b.partName));
        const group = mapToGroupItem(
          groupName,
          groupParts,
          PartTitle.Name,
          state
        );
        list.push(group);
      });

      return {
        ...state,
        groups: list,
      };
    }
    case "increment": {
      const group = state.groups.find((x) =>
        x.parts.some((y) => y.invPartId === action.invPartId)
      );
      if (!group) {
        return state;
      }

      const part = group.parts.find((x) => x.invPartId === action.invPartId);
      if (!part) {
        return state;
      }

      const partQuantity = +part.quantity;
      if (partQuantity === 0) {
        return state;
      }

      const partCount = group.counts.find(
        (x) => x.invPartId === action.invPartId
      );

      let newCountsState: LegoPartCountModel[] | null = null;
      let newCountValue: LegoPartCountModel | null = null;

      if (!partCount) {
        newCountValue = {
          invPartId: action.invPartId,
          count: incrementPartCount(null, partQuantity),
          updated: new Date(),
        };
        newCountsState = [...group.counts, newCountValue];
      } else if (partCount.count < partQuantity) {
        newCountValue = {
          invPartId: action.invPartId,
          count: incrementPartCount(partCount.count, partQuantity),
          updated: new Date(),
        };
        newCountsState = group.counts.map((x) =>
          x.invPartId === action.invPartId ? newCountValue! : x
        );
      }

      if (newCountsState) {
        const newGroupsState = state.groups.map((item) =>
          item.groupName === group.groupName
            ? { ...item, counts: newCountsState }
            : item
        );

        const newPending = new Map([
          ...state.pending,
          [action.invPartId, newCountValue!],
        ]);

        return {
          ...state,
          groups: newGroupsState,
          pending: newPending,
          countUpdatedDate: new Date(),
        };
      }

      return state;
    }
    case "decrement": {
      const group = state.groups.find((x) =>
        x.parts.some((y) => y.invPartId === action.invPartId)
      );
      if (!group) {
        return state;
      }

      const part = group.parts.find((x) => x.invPartId === action.invPartId);
      if (!part) {
        return state;
      }

      const partCount = group.counts.find(
        (x) => x.invPartId === action.invPartId
      );

      let newCountsState: LegoPartCountModel[] | null = null;
      let newCountItem: LegoPartCountModel | null = null;

      if (partCount) {
        newCountItem = {
          invPartId: action.invPartId,
          count: decrementPartCount(partCount.count, part.quantity),
          updated: new Date(),
        };
        newCountsState = group.counts.map((x) =>
          x.invPartId === action.invPartId ? newCountItem! : x
        );
      } else {
        newCountItem = {
          invPartId: action.invPartId,
          count: decrementPartCount(null, part.quantity),
          updated: new Date(),
        };
        newCountsState = [...group.counts, newCountItem];
      }

      if (newCountsState) {
        const newGroupsState = state.groups.map((item) =>
          item.groupName === group.groupName
            ? { ...item, counts: newCountsState }
            : item
        );

        const newPending = new Map([
          ...state.pending,
          [action.invPartId, newCountItem!],
        ]);

        return {
          ...state,
          groups: newGroupsState,
          pending: newPending,
          countUpdatedDate: new Date(),
        };
      }

      return state;
    }
    case "update-counts": {
      const updatedGroups: ChecklistGroupModel[] = [];

      state.groups.forEach((group) => {
        const groupCountsUpdates: LegoPartCountModel[] = [];
        group.parts.forEach((part) => {
          const partUpdateCount = action.counts.find(
            (x) => +x.invPartId === +part.invPartId
          );
          if (!partUpdateCount) {
            return;
          }

          const partCurrentCount = group.counts.find(
            (x) => +x.invPartId === +part.invPartId
          );
          if (canUpdatePartCount(partCurrentCount, partUpdateCount)) {
            groupCountsUpdates.push({
              invPartId: +part.invPartId,
              count: +partUpdateCount.count,
              updated: partUpdateCount.updated ?? new Date(),
            });
          }
        });

        if (groupCountsUpdates.length > 0) {
          const groupNewCounts = group.counts.map((item) => {
            const update = groupCountsUpdates.find(
              (x) => +x.invPartId === +item.invPartId
            );
            return update ?? item;
          });
          groupCountsUpdates.forEach((update) => {
            if (
              !groupNewCounts.find((x) => +x.invPartId === +update.invPartId)
            ) {
              groupNewCounts.push(update);
            }
          });

          updatedGroups.push({
            ...group,
            counts: groupNewCounts,
          });
        }
      });

      if (updatedGroups.length > 0) {
        return {
          ...state,
          groups: state.groups.map(
            (item) =>
              updatedGroups.find((x) => x.groupName === item.groupName) ?? item
          ),
        };
      }

      return state;
    }
    case "update-pending-for-retry": {
      const newPending = new Map([...state.pending]);
      let changed = false;
      action.counts.forEach((retryItem) => {
        const current = newPending.get(retryItem.invPartId);
        if (!current || isNewerDate(retryItem.updated, current.updated)) {
          newPending.set(retryItem.invPartId, retryItem);
          changed = true;
        }
      });

      if (!changed) {
        return state;
      }

      return {
        ...state,
        pending: newPending,
      };
    }
    case "clear-pending": {
      return {
        ...state,
        pending: new Map(),
      };
    }
    case "toggle-group": {
      const newGroupsState = state.groups.map((item) =>
        item.groupName === action.groupName
          ? { ...item, isExpanded: !item.isExpanded }
          : item
      );

      return {
        ...state,
        groups: newGroupsState,
      };
    }
    case "expand-all": {
      const newGroupsState = state.groups.map((item) => ({
        ...item,
        isExpanded: true,
      }));

      return {
        ...state,
        expandedState: true,
        groups: newGroupsState,
      };
    }
    case "collapse-all": {
      const newGroupsState = state.groups.map((item) => ({
        ...item,
        isExpanded: false,
      }));

      return {
        ...state,
        expandedState: false,
        groups: newGroupsState,
      };
    }
    case "hide-completed": {
      const newCompletedVisible = false;

      return {
        ...state,
        groups: state.groups.map((item) => ({
          ...item,
          parts: mapPartsListVisibility(item, item.parts, newCompletedVisible),
          isVisible: isGroupVisible(
            item,
            state.filterValue,
            newCompletedVisible
          ),
        })),
        completedVisible: newCompletedVisible,
      };
    }
    case "show-completed": {
      const newCompletedVisible = true;

      return {
        ...state,
        groups: state.groups.map((item) => ({
          ...item,
          parts: item.parts.map((part) => ({
            ...part,
            isVisible: true,
          })),
          isVisible: isGroupVisible(
            item,
            state.filterValue,
            newCompletedVisible
          ),
        })),
        completedVisible: newCompletedVisible,
      };
    }
    case "filter": {
      return {
        ...state,
        groups: state.groups.map((item) => ({
          ...item,
          isVisible: isGroupVisible(
            item,
            action.filter,
            state.completedVisible
          ),
        })),
        filterValue: action.filter,
      };
    }
    default:
      return state;
  }
}
