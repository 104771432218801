import { ChecklistStorage } from "../../../../lib/checklistsStorage";
import { useUserChecklistsDispatch } from "../../contexts/UserChecklistsContext";
import { ChecklistItemModel } from "../../store/checklistsListReducer.state";

export default function ChecklistMenuItems({
  item,
}: {
  item: ChecklistItemModel;
}) {
  const dispatch = useUserChecklistsDispatch();

  function handleArchive(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    const s = new ChecklistStorage();
    s.archive(item.name);
    dispatch({ type: "archive", name: item.name });
    close(e);
  }

  function handleCopyName(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    navigator.clipboard.writeText(item.name);
    close(e);
  }

  function close(e: React.MouseEvent<HTMLAnchorElement>) {
    e.target.dispatchEvent(
      new Event("close-menu", { bubbles: true, composed: true })
    );
  }

  return (
    <>
      <li className="menu-copy">
        <a href="#" onClick={(e) => handleCopyName(e)}>
          Copy name
        </a>
      </li>
      <li className="menu-archive">
        <a href="#" onClick={(e) => handleArchive(e)}>
          Archive
        </a>
      </li>
      {/* <li className="menu-delete"><a href="#" onClick={(e) => handleClick(e)}>Delete</a></li> */}
    </>
  );
}
