import { useState, useEffect } from "react";
import { LegoPartModel } from "../../../models";
import { Status } from "../../dashboard/models";
import { extractSetNameFromChecklistName } from "../lib/helpers";
import { createSetDetailsAPI } from "../../../lib/apiSet";

export const useLoadSetDetails = (
  checklistName: string | undefined
): {
  data: LegoPartModel[] | null;
  status: Status;
} => {
  const [data, setData] = useState<LegoPartModel[] | null>(null);
  const [status, setStatus] = useState(Status.None);

  useEffect(() => {
    if (!checklistName) {
      setData(null);
      setStatus(Status.None);
      return;
    }

    setStatus(Status.Loading);

    const setNumber = extractSetNameFromChecklistName(checklistName);
    if (!setNumber) {
      console.error("Set number not found");
      setStatus(Status.Error);
      return;
    }

    const api = createSetDetailsAPI();
    api
      .loadSetDetails(setNumber)
      .then((setDetails) => {
        if (!setDetails) {
          setStatus(Status.Error);
          return;
        }

        setData(setDetails);
        setStatus(Status.Loaded);
      })
      .catch(() => {
        setStatus(Status.Error);
      });
  }, [checklistName]);

  return { data, status };
};
