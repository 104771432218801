import { useNavigate } from "react-router-dom";
import { Status } from "../../models";
import { ChecklistItemModel } from "../../store/checklistsListReducer.state";
import { ContextMenuWithToggle } from "../ContextMenu";
import ChecklistMenuItems from "./ChecklistMenuItems";
import ChecklistDetails from "./ChecklistDetails";
import SetDetailsImage from "./SetDetailsImage";

export default function UserChecklistItem({
  item,
}: {
  item: ChecklistItemModel;
}) {
  const nav = useNavigate();

  function continueChecklist() {
    nav("/checklist/" + item.name);
    window.scrollTo(0, 0);
  }

  return (
    <li className="checklist-item" data-testid="user-checklist-item">
      <div className="cover">
        <SetDetailsImage item={item.setDetails} status={item.detailsStatus} />
        <ContextMenuWithToggle>
          <ChecklistMenuItems item={item} />
        </ContextMenuWithToggle>
      </div>
      <div className="title">
        <span className="number set-number">{item.setNumber}</span>
        {item.setDetails && item.detailsStatus === Status.Loaded ? (
          <b className="name">{item.setDetails.name}</b>
        ) : (
          <div className="skeleton"></div>
        )}
      </div>
      <div className="details">
        <ChecklistDetails
          item={item.checklistDetails}
          status={item.checklistStatus}
        />
      </div>
      <div className="buttons">
        <button onClick={continueChecklist} className="continue">
          Continue
        </button>
      </div>
    </li>
  );
}