import { useState } from "react";
import { CoverModel, Status } from "../models";
import SetSearchForm from "../components/SetSearchForm";
import { createSetDetailsAPI } from "../../../lib/apiSet";

export default function CoverFinder({
  onResult,
  onStatusChange,
}: {
  onResult: (item: CoverModel | null) => void;
  onStatusChange: (status: Status) => void;
}) {
  const [status, setStatus] = useState<Status>(Status.None);

  function changeStatus(newStatus: Status) {
    onStatusChange(newStatus);
    setStatus(newStatus);
  }

  function onFindSet(setName: string) {
    onResult(null);
    changeStatus(Status.Loading);

    const api = createSetDetailsAPI();
    api.loadSetCover(setName)
      .then((item) => {
        if (item) {
          onResult(item);
          changeStatus(Status.None);
        } else {
          changeStatus(Status.Error);
        }
      })
      .catch(() => changeStatus(Status.Error));
  }

  return (
    <>
      <h3>Find LEGO set and start checklist</h3>
      <SetSearchForm onFindSet={onFindSet}></SetSearchForm>
      {status === Status.Error && (
        <div className="message" data-testid="search-set-not-exists">
          LEGO set not found.
        </div>
      )}
      {status === Status.Loading && <progress></progress>}
      <div className="variants-info">
        LEGO sets sometimes have variants (versions). In most cases just
        type set number from the box and leave the variant as "1".
      </div>
    </>
  );
}
