import "./App.css";
import { Routes, Route, HashRouter } from "react-router-dom";
import Checklist from "./pages/checklist/Checklist";
import Dashboard from "./pages/dashboard/Dashboard";
import useCookieConsent from "./hooks/useCookieConsent";

export default function App() {
  useCookieConsent();
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/checklist/:checklistName" element={<Checklist />} />
        </Routes>
      </HashRouter>
    </>
  );
}
