import { useEffect } from "react";
import { ChecklistsListsAction } from "../store/checklistsListReducer.actions";
import { ChecklistStorage } from "../../../lib/checklistsStorage";

export const useChecklistsLocalStorage = (
  dispatch: React.Dispatch<ChecklistsListsAction>
) => {
  useEffect(() => {
    const s = new ChecklistStorage();
    const names = s.load();
    if (names) {
      dispatch({ type: "loaded", names: names });
    }
    const archive = s.loadArchive();
    if (archive) {
      dispatch({ type: "archive-loaded", items: archive });
    }
  }, [dispatch]);
};
