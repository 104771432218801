import React from "react";
import { createContext, useContext } from "react";
import { LegoPartCountModel } from "../../../models";
import { ChecklistGroupModel, LegoPartChecklistModel } from "../store/checklistReducer.state";

export interface PartsGroupContextModel {
  groupName: string;
  parts: LegoPartChecklistModel[];
  counts: LegoPartCountModel[];
  isExpanded: boolean;
  totalCounts: number;
  isCompleted: boolean;
  checklistLoaded: boolean;
}

export const PartsGroupContext = createContext<PartsGroupContextModel | null>(
  null
);

interface PartsGroupContextProviderProps {
  item: ChecklistGroupModel;
  isCompleted: boolean;
  totalCounts: number;
  checklistLoaded: boolean;
  children: React.ReactNode;
}

function PartsGroupContextProviderInner({
  item,
  isCompleted,
  totalCounts,
  checklistLoaded, 
  children,
}: PartsGroupContextProviderProps) {
  const state = {
    groupName: item.groupName,
    parts: item.parts,
    counts: item.counts,
    isExpanded: item.isExpanded,
    totalCounts: totalCounts,
    isCompleted: isCompleted,
    checklistLoaded: checklistLoaded
  };

  return (
    <PartsGroupContext.Provider value={state}>
      {children}
    </PartsGroupContext.Provider>
  );
}

export const PartsGroupContextProvider = React.memo(
  PartsGroupContextProviderInner,
  (prev: PartsGroupContextProviderProps, next: PartsGroupContextProviderProps) => {
    const result = (
      prev.item.groupName === next.item.groupName &&
      prev.item.isExpanded === next.item.isExpanded &&
      prev.item.parts === next.item.parts &&
      prev.isCompleted === next.isCompleted &&
      prev.totalCounts === next.totalCounts &&
      prev.checklistLoaded === next.checklistLoaded
    );
    // console.log("PartsGroupContextProvider", result, prev, next);
    return result;
  }
);

export const usePartsGroupContext = (): PartsGroupContextModel => {
  const context = useContext(PartsGroupContext);
  if (!context) {
    throw new Error(
      "usePartsGroupContext must be used within a PartsGroupContextProvider"
    );
  }
  return context;
};
