import { CoverModel, Status } from "../../models";

export default function SetDetailsImage({
  item,
  status,
}: {
  item: CoverModel | null;
  status: Status;
}) {
  return (
    <div className="cover-image">
      {status === Status.Error && <div></div>}
      {status === Status.Loading && <div className="skeleton"></div>}
      {status === Status.Loaded && item && (
        <img src={item.set_img_url} alt={item.name} />
      )}
    </div>
  );
}
