import { BehaviorSubject, Subject } from "rxjs";
import { LegoPartCountModel } from "../../../models";
import { ChecklistAPI } from "../../../lib/apiChecklist";

export interface ProgressModel {
  foundParts: number;
  progress: number;
}

export interface SavedInfoModel {
  saved: LegoPartCountModel[];
  result: LegoPartCountModel[];
}

export enum SaveStatus {
  None,
  Saving,
  Failed,
}

export class PendingChanges {
  #totalParts: number = 0;

  currentStatus = SaveStatus.None;
  status$ = new BehaviorSubject<SaveStatus>(SaveStatus.None);
  saved$ = new Subject<SavedInfoModel>();
  failed$ = new Subject<LegoPartCountModel[]>();
  pendingChanges: LegoPartCountModel[] = [];
  savingTimeout: NodeJS.Timeout;

  constructor(private checklistName: string, private api: ChecklistAPI) {
    this.savingTimeout = setInterval(() => {
      if (this.pendingChanges.length === 0) {
        return;
      }

      if (this.currentStatus === SaveStatus.Saving) {
        console.log("Skipping save");
        return;
      }

      this.save();
    }, 3000);

    this.status$.subscribe((status) => {
      this.currentStatus = status;
    });
  }

  save() {
    const pendingChangesCopy = [...this.pendingChanges];
    this.pendingChanges = [];

    const data = this.#getLatestCountByPart(pendingChangesCopy);
    console.log("Saving", data);
    this.status$.next(SaveStatus.Saving);

    this.api
      .save(this.checklistName, data, this.#totalParts)
      .then((counts) => {
        console.log("Saved", counts);
        this.saved$.next({
          saved: data,
          result: counts,
        });
        this.status$.next(SaveStatus.None);
      })
      .catch(() => {
        console.log("Failed to save", data);
        this.pendingChanges = [...pendingChangesCopy, ...this.pendingChanges];
        this.status$.next(SaveStatus.Failed);
        this.failed$.next(data);
      });
  }

  cleanUp() {
    clearInterval(this.savingTimeout);
  }

  addPendingChange(item: LegoPartCountModel) {
    this.pendingChanges.push(item);
  }

  setTotalParts(total: number) {
    this.#totalParts = total;
  }

  #getLatestCountByPart(data: LegoPartCountModel[]) {
    const grouped: { [key: number]: LegoPartCountModel } = {};
    data.forEach((item) => {
      if (
        !grouped[item.invPartId] ||
        grouped[item.invPartId]?.updated < item.updated
      ) {
        grouped[item.invPartId] = item;
      }
    });

    const output: LegoPartCountModel[] = [];
    Object.entries(grouped).forEach(([, value]) => {
      output.push(value);
    });
    return output;
  }
}
