import { extractSetNameFromChecklistName } from "../../checklist/lib/helpers";
import { Status } from "../models";
import { ChecklistsListsAction } from "./checklistsListReducer.actions";
import {
  ChecklistItemModel,
  ChecklistsListState,
} from "./checklistsListReducer.state";

export function createChecklistsListState(): ChecklistsListState {
  return {
    list: [],
    archive: [],
  };
}

export function checklistsListReducer(
  state: ChecklistsListState,
  action: ChecklistsListsAction
): ChecklistsListState {
  switch (action.type) {
    case "loaded": {
      return {
        ...state,
        list: action.names.map(
          (name): ChecklistItemModel => ({
            name: name,
            setNumber: extractSetNameFromChecklistName(name),
            checklistStatus: Status.None,
            detailsStatus: Status.None,
            checklistDetails: null,
            setDetails: null,
          })
        ),
      };
    }
    case "checklists-loading": {
      if (!action.names.length) {
        return state;
      }

      return {
        ...state,
        list: state.list.map((item): ChecklistItemModel => {
          if (action.names.includes(item.name)) {
            return {
              ...item,
              checklistStatus: Status.Loading,
            };
          }
          return item;
        }),
      };
    }
    case "checklists-loaded": {
      if (!action.details.length) {
        return state;
      }

      return {
        ...state,
        list: state.list.map((item): ChecklistItemModel => {
          const details = action.details.find((d) => d.name === item.name);
          if (details) {
            return {
              ...item,
              checklistStatus: Status.Loaded,
              checklistDetails: {
                name: details.name,
                created: new Date(details.created),
                updated: details.updated ? new Date(details.updated) : null,
                progress: details.progress,
              },
            };
          } else {
            return item;
          }
        }),
      };
    }
    case "checklists-loading-failed": {
      if (!action.names.length) {
        return state;
      }

      return {
        ...state,
        list: state.list.map((item): ChecklistItemModel => {
          if (action.names.includes(item.name)) {
            return {
              ...item,
              checklistStatus: Status.Error,
              checklistDetails: null,
            };
          }
          return item;
        }),
      };
    }
    case "set-loading": {
      if (!action.numbers.length) {
        return state;
      }

      return {
        ...state,
        list: state.list.map((item): ChecklistItemModel => {
          if (action.numbers.includes(item.setNumber)) {
            return {
              ...item,
              detailsStatus: Status.Loading,
            };
          }
          return item;
        }),
      };
    }
    case "set-loaded": {
      if (!action.details.length) {
        return state;
      }

      return {
        ...state,
        list: state.list.map((item): ChecklistItemModel => {
          const details = action.details.find(
            (d) => d.set_num === item.setNumber
          );
          if (details) {
            return {
              ...item,
              detailsStatus: Status.Loaded,
              setDetails: details,
            };
          } else {
            return item;
          }
        }),
      };
    }
    case "set-loading-failed": {
      if (!action.numbers.length) {
        return state;
      }

      return {
        ...state,
        list: state.list.map((item): ChecklistItemModel => {
          if (action.numbers.includes(item.setNumber)) {
            return {
              ...item,
              detailsStatus: Status.Error,
              setDetails: null,
            };
          }
          return item;
        }),
      };
    }
    case "archive": {
      return {
        ...state,
        list: state.list.filter((item) => item.name !== action.name),
        archive: [
          ...state.archive,
          {
            name: action.name,
            date: new Date(),
          },
        ],
      };
    }
    case "archive-loaded": {
      return {
        ...state,
        archive: action.items,
      };
    }
    case "restore": {
      return {
        ...state,
        list: [
          ...state.list,
          {
            name: action.name,
            setNumber: extractSetNameFromChecklistName(action.name),
            checklistStatus: Status.None,
            detailsStatus: Status.None,
            checklistDetails: null,
            setDetails: null,
          },
        ],
        archive: state.archive.filter((x) => x.name !== action.name),
      };
    }
    default:
      throw new Error("Unknown action type");
  }
}
