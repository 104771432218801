import { LegoPartWithCountModel } from "../../../../models";
import { useChecklistCountsDispatch } from "../../contexts/ChecklistContext";

export default function PartControls({
  item,
  loaded,
}: {
  item: LegoPartWithCountModel;
  loaded: boolean;
}) {
  const dispatch = useChecklistCountsDispatch();

  return (
    <div className="panel">
      <button
        data-testid="decrement"
        className="sub"
        disabled={!loaded}
        onClick={() =>
          dispatch({ type: "decrement", invPartId: item.part.invPartId })
        }
      >
        -
      </button>
      <div className={"count" + (!loaded && item.count === null ? " skeleton" : "")}>{item.count}</div>
      <div> / </div>
      <div className="quantity">{item.part.quantity}</div>
      <button
        data-testid="increment"
        className="add"
        disabled={!loaded}
        onClick={() =>
          dispatch({ type: "increment", invPartId: item.part.invPartId })
        }
      >
        +
      </button>
    </div>
  );
}
