import { Dispatch, createContext, useContext, useReducer } from "react";
import { checklistReducer } from "../store/checklistReducer";
import { ChecklistAction } from "../store/checklistReducer.actions";
import { ChecklistCountState, createInitialState } from "../store/checklistReducer.state";

export const ChecklistCountsContext = createContext<ChecklistCountState | null>(
  null
);
export const ChecklistCountsDispatchContext =
  createContext<Dispatch<ChecklistAction> | null>(null);

export function ChecklistCountProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(
    checklistReducer,
    createInitialState(),
  );

  return (
    <ChecklistCountsContext.Provider value={state}>
      <ChecklistCountsDispatchContext.Provider value={dispatch}>
        {children}
      </ChecklistCountsDispatchContext.Provider>
    </ChecklistCountsContext.Provider>
  );
}

export function useChecklistCounts() {
  const state = useContext(ChecklistCountsContext);
  if (!state) {
    throw new Error(
      "useChecklistCounts must be used within a ChecklistCountsContext"
    );
  }
  return state;
}

export function useChecklistCountsDispatch() {
  const dispatch = useContext(ChecklistCountsDispatchContext);
  if (!dispatch) {
    throw new Error(
      "useChecklistCountsDispatch must be used within a ChecklistCountsDispatchContext"
    );
  }
  return dispatch;
}
