import "./setSearchForm.css";
import { useFormInput } from "../hooks/useFormInput";

export default function SetSearchForm({
  onFindSet,
}: {
  onFindSet: (setName: string) => void;
}) {
  const validateNumber = (value: string) => !!value?.match(/^\d+$/);

  const inputNumber = useFormInput("", (value) => validateNumber(value));
  const inputVariant = useFormInput("1", (value) => validateNumber(value));

  function onSubmit(e: Event | React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!inputNumber.valid || !inputVariant.valid) {
      return;
    }

    const setName = `${inputNumber.value}-${inputVariant.value}`;
    onFindSet(setName);
  }

  return (
    <div className="search-container">
      <form className="form" onSubmit={onSubmit}>
        <input
          data-testid="search-input-number"
          type="text"
          inputMode="numeric"
          required
          className={
            "number" + (inputNumber.valid ? "" : " " + "invalid")
          }
          onChange={(e) => {
            inputNumber.setValue(e.target.value);
          }}
        />
        -
        <input
          type="text"
          data-testid="search-input-variant"
          inputMode="numeric"
          className={
            "variant" + (inputVariant.valid ? "" : " " + "invalid")
          }
          required
          value={inputVariant.value}
          onChange={(e) => {
            inputVariant.setValue(e.target.value);
          }}
        />
        <button id="search" type="submit" data-testid="search-submit">
          Find
        </button>
      </form>
    </div>
  );
}
