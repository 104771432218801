import Part from "../part/Part";
import "./partsGroup.css";
import PartsGroupHeader from "./PartsGroupHeader";
import { useRef } from "react";
import { useIsIntersecting } from "../../hooks/useIsIntersecting";
import { usePartsGroupContext } from "../../contexts/PartsGroupContext";
import PartControls from "../part/PartControls";
import { LegoPartChecklistModel } from "../../store/checklistReducer.state";

export interface LegoChecklistPartWithCountModel {
  part: LegoPartChecklistModel;
  count: number | null;
}

export function PartsGroup() {
  const { parts, counts, isExpanded, checklistLoaded } = usePartsGroupContext();

  const ref = useRef<HTMLDivElement | null>(null);
  const isIntersecting = useIsIntersecting(ref);

  const defaultCountValue = checklistLoaded ? 0 : null;

  const partsWithCounts: LegoChecklistPartWithCountModel[] = parts.map(
    (item): LegoChecklistPartWithCountModel => {
      const count = counts.find((x) => x.invPartId === item.invPartId);
      return {
        part: item,
        count: count?.count ? +count.count : defaultCountValue,
      };
    }
  );

  return (
    <div
      className={"parts-group-container " + (isExpanded ? "" : " collapsed")}
    >
      <div className={"parts-group"} ref={ref}>
        <PartsGroupHeader></PartsGroupHeader>
        <div className="parts-list">
          {isIntersecting &&
            partsWithCounts.map(
              (item) =>
                item.part.isVisible && (
                  <Part key={item.part.invPartId} item={item}>
                    <PartControls item={item} loaded={checklistLoaded}></PartControls>
                  </Part>
                )
            )}
        </div>
      </div>
    </div>
  );
}
