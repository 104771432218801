import { useState } from "react";
import { ChecklistStorage } from "../../../../lib/checklistsStorage";
import { extractSetNameFromChecklistName } from "../../../checklist/lib/helpers";
import {
  useUserChecklists,
  useUserChecklistsDispatch,
} from "../../contexts/UserChecklistsContext";
import { ArchiveItem } from "../../store/checklistsListReducer.state";

export default function ArchivedList() {
  const checklists = useUserChecklists();
  const dispatch = useUserChecklistsDispatch();

  const [archiveVisible, setArchiveVisible] = useState(false);

  function showArchive(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    setArchiveVisible(!archiveVisible);
  }

  function restore(e: React.MouseEvent<HTMLButtonElement>, name: string) {
    e.preventDefault();
    const s = new ChecklistStorage();
    s.restore(name);
    dispatch({ type: "restore", name: name });
  }
  return (
    <div className="archive">
      {checklists.archive.length > 0 && (
        <div className="info">
          Archived checklists: {checklists.archive.length}{" "}
          <a href="#" onClick={(e) => showArchive(e)}>
            show
          </a>
        </div>
      )}
      {archiveVisible && (
        <ul className="archive-list">
          {checklists.archive.map((item) => {
            return <ArchiveListItem  key={item.name} item={item} restore={restore} />;
          })}
        </ul>
      )}
    </div>
  );
}

function ArchiveListItem({
  item,
  restore,
}: {
  item: ArchiveItem;
  restore: (e: React.MouseEvent<HTMLButtonElement>, name: string) => void;
}) {
  return (
    <div>
      <div>{extractSetNameFromChecklistName(item.name)} </div>
      <button className="btn-secondary" onClick={(e) => restore(e, item.name)}>
        restore
      </button>
    </div>
  );
}
