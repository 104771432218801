import { LegoPartModel } from "../../../models";

export function groupListByColorName(
  details: LegoPartModel[]
): PartsByPartName {
  details.sort((a: LegoPartModel, b: LegoPartModel) =>
    a.color.localeCompare(b.color)
  );

  const groups = details.reduce((acc: PartsByPartName, item: LegoPartModel) => {
    const groupName: string = item.color;

    if (!acc[groupName]) {
      acc[groupName] = [];
    }

    acc[groupName].push({
      ...item,
      invPartId: +item.invPartId,
      quantity: +item.quantity,
    });

    return acc;
  }, {} as PartsByPartName);

  return groups;
}

export type PartsByPartName = {
  [key: string]: LegoPartModel[];
};
