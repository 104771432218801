import { useEffect, useState } from "react";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import { PartsGroup } from "./components/group/PartsGroup";
import { Status } from "../dashboard/models";
import {
  ChecklistCountProvider,
  useChecklistCounts,
  useChecklistCountsDispatch,
} from "./contexts/ChecklistContext";
import { ChecklistGroupModel } from "./store/checklistReducer.state";
import { PartsGroupContextProvider } from "./contexts/PartsGroupContext";
import useRememberVisitedChecklist from "./hooks/useRememberVisitedChecklist";
import { useParams } from "react-router-dom";
import { useSavePendingCounts } from "./hooks/useSavePendingCounts";
import LoadingStatus from "./components/LoadingStatus";
import { useLoadChecklistDetails } from "./hooks/useLoadChecklistDetails";
import { useLoadSetDetails } from "./hooks/useLoadSetDetails";

export default function Checklist() {
  return (
    <ChecklistCountProvider>
      <ChecklistComponent></ChecklistComponent>
    </ChecklistCountProvider>
  );
}

function ChecklistComponent() {
  const { checklistName } = useParams();
  const [reloadChecklist, setReloadChecklist] = useState(0);
  const { data: setDetails, status: setDetailsStatus } =
    useLoadSetDetails(checklistName);
  const { data: checklist, status: checklistStatus } = useLoadChecklistDetails(
    checklistName,
    reloadChecklist
  );
  const [totalParts, setTotalParts] = useState(0);
  const dispatch = useChecklistCountsDispatch();
  const state = useChecklistCounts();
  useRememberVisitedChecklist(checklistName);
  const [saveStatus] = useSavePendingCounts(checklistName, dispatch, state.pending, totalParts);

  function onReloadChecklist() {
    setReloadChecklist(reloadChecklist + 1);
  }

  useEffect(() => {
    if (setDetails) {
      dispatch({
        type: "group-by-part-name",
        parts: setDetails ?? [],
      });
      const parts = setDetails.reduce((acc, part) => acc + part.quantity, 0);
      setTotalParts(parts);
    }
  }, [setDetails, dispatch]);

  useEffect(() => {
    if (setDetails) {
      dispatch({
        type: "counts-loaded",
        counts: checklist ?? [],
      });
    }
  }, [checklist, setDetails, dispatch]);

  return (
    <>
      <Header></Header>
      <LoadingStatus
        setDetailsStatus={setDetailsStatus}
        checklistStatus={checklistStatus}
        onTryChecklistAgain={onReloadChecklist}
      />
      <div className="list">
        {state.groups.map((item) => {
          const info = calculateGroupInfo(item);
          return (
            item.isVisible && (
              <PartsGroupContextProvider
                key={item.groupName}
                item={item}
                checklistLoaded={checklistStatus === Status.Loaded}
                totalCounts={info.totalCounts}
                isCompleted={info.isCompleted}
              >
                <PartsGroup />
              </PartsGroupContextProvider>
            )
          );
        })}
      </div>
      <Footer
        onRefresh={onReloadChecklist}
        saveStatus={saveStatus}
        loadStatus={checklistStatus}
      ></Footer>
    </>
  );
}


interface ChecklistGroupInfoModel {
  totalCounts: number;
  totalQuantity: number;
  isCompleted: boolean;
}

function calculateGroupInfo(
  item: ChecklistGroupModel
): ChecklistGroupInfoModel {
  const totalCounts = item.counts.reduce((acc, count) => acc + count.count, 0);
  const totalQuantity = item.parts.reduce(
    (acc, part) => acc + part.quantity,
    0
  );
  const isCompleted = totalCounts === totalQuantity;
  return {
    totalCounts,
    totalQuantity,
    isCompleted,
  };
}
