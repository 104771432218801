import UserChecklistItem from "../components/userChecklists/UserChecklistItem";
import { useUserChecklists, useUserChecklistsDispatch } from "../contexts/UserChecklistsContext";
import { useChecklistDetailsLoading } from "../hooks/useChecklistDetails";
import { useChecklistsLocalStorage } from "../hooks/useChecklistsLocalStorage";
import { useSetDetailsLoading } from "../hooks/useSetDetailsLoading";
import "./userChecklists.css";

export function UserChecklists() {
  const checklists = useUserChecklists();
  const dispatch = useUserChecklistsDispatch();
  useChecklistsLocalStorage(dispatch);
  useChecklistDetailsLoading(checklists.list, dispatch);
  useSetDetailsLoading(checklists.list, dispatch);

  return (
    <>
      <ul className="user-checklists">
        {checklists.list.map((item) => {
          return <UserChecklistItem key={item.name} item={item} />;
        })}
      </ul>
      {checklists.list.length === 0 && <p>No checklists yet...</p>}
      <div className="checklists-info">
        Checklist are "not public" meaning that they are accessible by everyone
        who knows the checklist's URL. When you create a new checklist or visit
        checklist created by your friend it will be revered in your browser and
        will appear here.
      </div>
    </>
  );
}


