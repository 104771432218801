import { useState } from "react";
import { LegoPartFilterValue } from "../../models";
import "./filter.css";

export default function Filter({
  onFilter,
}: {
  onFilter: (value: LegoPartFilterValue) => void;
}) {
  const [name, setName] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [sizeW, setSizeW] = useState<number>(0);
  const [sizeL, setSizeL] = useState<number>(0);
  const [sizeH, setSizeH] = useState<number>(0);

  function resetFilter() {
    setName("");
    setText("");
    setSizeW(0);
    setSizeL(0);
    setSizeH(0);
    onFilter({
      name: "",
      text: "",
      w: 0,
      l: 0,
      h: 0,
    });
  }

  function filter() {
    const value: LegoPartFilterValue = {
      name,
      text,
      w: sizeW,
      l: sizeL,
      h: sizeH,
    };
    onFilter(value);
  }

  return (
    <div className="filter-container">
      <div className="params">
        <div className="params-row filter-by-part-type">
          <label className="label part-name-label">Name:</label>
          <select
            className="part-name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          >
            <option value=""></option>
            <option value="Plate">Plate</option>
            <option value="Brick">Brick</option>
            <option value="Tile">Tile</option>
          </select>
        </div>
        <div className="params-row">
          <div className="label ">Size:</div>
          <div className="box">
            <select
              className="part-size part-size-w"
              value={sizeW}
              onChange={(e) => {
                const size = parseInt(e.target.value);
                setSizeW(isNaN(size) ? 0 : size);
              }}
            >
              <option value=""></option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>
          <div>x</div>
          <div className="box">
            <select
              className="part-size part-size-l"
              value={sizeL}
              onChange={(e) => {
                const size = parseInt(e.target.value);
                setSizeL(isNaN(size) ? 0 : size);
              }}
            >
              <option value=""></option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>
          <div>x</div>
          <div className="box">
            <select
              className="part-size part-size-h"
              value={sizeH}
              onChange={(e) => {
                const size = parseInt(e.target.value);
                setSizeH(isNaN(size) ? 0 : size);
              }}
            >
              <option value=""></option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="params-row search">
          <input
            type="text"
            className="text"
            placeholder="Search text..."
            maxLength={20}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
      </div>
      <div className="actions">
        <button className="reset btn-secondary" onClick={resetFilter}>
          Reset
        </button>
        <button className="submit" onClick={filter}>
          OK
        </button>
      </div>
    </div>
  );
}
