import { LegoPartModel } from "../models";
import { CoverModel } from "../pages/dashboard/models";

export interface SetDetailsAPI {
  loadSetDetails(setNumber: string): Promise<LegoPartModel[]>;
  loadSetCover(setNumber: string): Promise<CoverModel>;
}

export function createSetDetailsAPI(): SetDetailsAPI {
  const baseUrl = import.meta.env.VITE_API_SETS_URL;

  // if (import.meta.env.VITE_API_SETS_USE_GRAPHQL) {
  //   return new SetDetailsGraphQLAPI(baseUrl);
  // } else {
  return new SetDetailsRESTApi(baseUrl);
  //}
}

class SetDetailsRESTApi implements SetDetailsAPI {
  constructor(private baseUrl: string) {}

  loadSetDetails(setNumber: string): Promise<LegoPartModel[]> {
    return fetch(`${this.baseUrl}details/${setNumber}`, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Set details not found");
        }

        return response.json();
      })
      .catch((e) => {
        console.error(e);
        return null;
      });
  }

  loadSetCover(setNumber: string): Promise<CoverModel> {
    return fetch(`${this.baseUrl}find/${setNumber}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return response;
      })
      .then((response) => response.json())
      .then((data: CoverModel) => {
        return data;
      });
  }
}

class SetDetailsGraphQLAPI implements SetDetailsAPI {
  constructor(private baseUrl: string) {}

  loadSetDetails(setNumber: string): Promise<LegoPartModel[]> {
    const payload = {
      operationName: "SetDetails",
      query: `query SetDetails($setNum: ID!) {
        parts(setNum: $setNum) {
         color
         colorRGB
         imageUrl
         invPartId
         isTrans
         partCatId
         partName
         partNum
         quantity 
        }
      }
      `,
      variables: {
        setNum: setNumber,
      },
    };

    return fetch(this.baseUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Set details not found");
        }

        return response.json();
      })
      .then((json) => {
        return json.data.parts;
      });
  }

  loadSetCover(setNumber: string): Promise<CoverModel> {
    const payload = {
      operationName: "LegoSet",
      query: `query LegoSet($num: ID!) {
        set(setNum: $num) {
          set_num
          name
          num_parts
          year
          set_img_url
        }
      }
      `,
      variables: {
        num: setNumber,
      },
    };

    return fetch(this.baseUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Set details not found");
        }

        return response.json();
      })
      .then((json) => {
        return json.data.set;
      });
  }
}
