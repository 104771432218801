import { CoverModel } from "../models";
import styles from "./Cover.module.css";

export default function Cover({
  item,
  children,
}: {
  item: CoverModel | null;
  children: React.ReactNode;
}) {
  if (item === null) {
    return null;
  }

  return (
    <div className={styles.cover} data-testid="set-cover">
      <div className={styles["title"]} data-testid="name">{item.name}</div>
      <div className={styles["info"]}>
        <div className="set-number" data-testid="number">{item.set_num}</div>
        <div>Parts: {item.num_parts}</div> <div>Year: {item.year}</div>
      </div>
      <img src={item.set_img_url} alt={item.name} />
      {children}
    </div>
  );
}
