import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cover from "../components/Cover";
import { Status, CoverModel } from "../models";
import CoverFinder from "./CoverFinder";
import { createChecklistAPI } from "../../../lib/apiChecklist";

export default function FinderAndCover() {
  const [status, setStatus] = useState<Status>(Status.None);
  const [coverItem, setCoverItem] = useState<CoverModel | null>();
  const nav = useNavigate();

  function onCreateChecklist(setNumber: string) {
    if (!setNumber) {
      return;
    }

    setStatus(Status.Loading);
    const api = createChecklistAPI();
    api.create(setNumber)
      .then((data) => {
        if (!data?.name) {
          setStatus(Status.Error);
          return;
        }
        window.scrollTo(0, 0);
        nav(`/checklist/${data.name}`);
      })
      .catch((e) => {
        console.error(e);
        setStatus(Status.Error);
      });
  }

  function onCoverFinderResult(item: CoverModel | null) {
    setCoverItem(item);
  }

  function onCoverLoadingStatus(status: Status) {
    setStatus(status);
  }

  return (
    <>
      {!coverItem && (
        <CoverFinder
          onResult={onCoverFinderResult}
          onStatusChange={onCoverLoadingStatus}
        />
      )}
      {coverItem && (
        <>
          <Cover item={coverItem}>
            <button
              data-testid="create-checklist-button"
              disabled={status === Status.Loading}
              className="btn"
              onClick={() => onCreateChecklist(coverItem.set_num)}
            >
              Start a new checklist
            </button>
            <div className="cover-back">
              or{" "}
              <button
                className="btn-secondary"
                onClick={() => setCoverItem(null)}
              >
                try again
              </button>
            </div>
            {status === Status.Loading && (
              <div style={{ margin: "0 auto" }}>
                <progress></progress>
              </div>
            )}
            {status === Status.Error && (
              <div style={{ margin: "0 auto" }}>
                Creating checklist failed. Please try again.
              </div>
            )}
          </Cover>
        </>
      )}
    </>
  );
}
