import { useState, useEffect } from "react";
import { LegoPartCountModel } from "../../../models";
import { Status } from "../../dashboard/models";
import { createChecklistAPI } from "../../../lib/apiChecklist";

export const useLoadChecklistDetails = (
  checklistName: string | undefined,
  reload: number
): {
  data: LegoPartCountModel[] | null;
  status: Status;
} => {
  const [data, setData] = useState<LegoPartCountModel[] | null>(null);
  const [status, setStatus] = useState(Status.None);

  useEffect(() => {
    if (!checklistName) {
      setData(null);
      setStatus(Status.None);
      return;
    }

    setStatus(Status.Loading);
    const api = createChecklistAPI();
    api
      .load(checklistName)
      .then((checklist) => {
        if (!checklist) {
          setStatus(Status.Error);
          return;
        }

        const checklistModel: LegoPartCountModel[] = checklist.map(
          (item): LegoPartCountModel => {
            const updated = item.updated ? new Date(item.updated) : new Date();

            return {
              ...item,
              updated,
            };
          }
        );

        setData(checklistModel);
        setStatus(Status.Loaded);
      })
      .catch(() => {
        setStatus(Status.Error);
      });
  }, [checklistName, reload]);

  return { data, status };
};
