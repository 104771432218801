import "./part.css";
import { LegoPartWithCountModel } from "../../../../models";

export default function Part({
  item,
  children,
}: {
  item: LegoPartWithCountModel;
  children: React.ReactNode;
}) {
  const isCompleted =
    item.count !== null && +item.count === +item.part.quantity;

  return (
    <div className={"lego-part" + (isCompleted ? " completed" : "")}>
      <div className="color-name">{item.part.title}</div>
      <div>
        <img
          src={item.part.imageUrl}
          title={`Part number: ${item.part.partNum}, inventory part id: ${item.part.invPartId}`}
        />
      </div>
      {children}
    </div>
  );
}
