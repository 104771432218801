import { formatDate } from "../../lib/date.helpers";
import { Status } from "../../models";
import { ChecklistDetailsModel } from "../../store/checklistsListReducer.state";

export default function ChecklistDetails({
  item,
  status,
}: {
  item: ChecklistDetailsModel | null;
  status: Status;
}) {
  return (
    <>
      {status === Status.Error && (
        <div className="error">
          Loading details failed. Sorry, but this checklist may not be
          available.
        </div>
      )}
      {status === Status.Loading && (
        <>
          <div className="date">
            <div className="label">Created:</div>{" "}
            <div className="skeleton"></div>
          </div>
          <div className="date">
            <div className="label">Updated:</div>{" "}
            <div className="skeleton"></div>
          </div>
          <div className="progress">
            <div className="skeleton"></div>
          </div>
        </>
      )}
      {status === Status.Loaded && (
        <>
          <div className="date">
            <div className="label">Created:</div>
            {item?.created && <div>{formatDate(item.created)}</div>}
          </div>

          <div className="date">
            <div className="label">Updated:</div>
            {item?.updated && <div>{formatDate(item.updated)}</div>}
          </div>

          {item?.progress && (
            <div className="progress">
              <progress max={100} value={item.progress}></progress>
              {item.progress}%
            </div>
          )}
        </>
      )}
    </>
  );
}