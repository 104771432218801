import { CoverModel } from "../pages/dashboard/models";

export class ChecklistStorage {
  storageKey = "checklists";
  storageKey_archive = "checklists-archive";

  load(): string[] {
    const data: string | null = localStorage.getItem(this.storageKey);
    if (!data) {
      return [];
    } else {
      const list = JSON.parse(data);
      return list || [];
    }
  }

  save(list: string[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(list));
  }

  add(checklistName: string): string[] {
    const data: string | null = localStorage.getItem(this.storageKey);
    let list: string[] = [];
    if (data) {
      list = JSON.parse(data);
    }
    if (!list.includes(checklistName)) {
      list.push(checklistName);
    }
    this.save(list);
    localStorage.setItem("lastChecklist", checklistName);

    return list;
  }

  loadArchive(): ArchiveItem[] {
    const data: string | null = localStorage.getItem(this.storageKey_archive);
    if (!data) {
      return [];
    } else {
      try {
        const list = JSON.parse(data);
        return list || [];
      } catch (e) {
        console.error(e);
        return [];
      }
    }
  }

  saveArchive(list: ArchiveItem[]): void {
    localStorage.setItem(this.storageKey_archive, JSON.stringify(list));
  }

  archive(checklistName: string) {
    const list = this.load().filter((name) => name !== checklistName);
    this.save(list);
    const archiveList = this.loadArchive();
    if (!archiveList.some((x) => x.name === checklistName)) {
      archiveList.push({
        name: checklistName,
        date: new Date(),
      });
      this.saveArchive(archiveList);
    }
  }

  restore(checklistName: string) {
    const list = this.load();
    if (!list.includes(checklistName)) {
      list.push(checklistName);
    }
    this.save(list);
    const archiveList = this.loadArchive();
    if (archiveList.some((x) => x.name === checklistName)) {
      this.saveArchive(archiveList.filter((x) => x.name !== checklistName));
    }
  }

  saveSetInfo(setNumber: string, cover: CoverModel): void {
    localStorage.setItem(`set-${setNumber}`, JSON.stringify(cover));
  }

  loadSetInfo(setNumber: string): CoverModel | null {
    try {
      const data: string | null = localStorage.getItem(`set-${setNumber}`);
      if (!data) {
        return null;
      }
      const cover: CoverModel = JSON.parse(data);
      if (
        cover.set_num !== setNumber ||
        !cover.name ||
        !cover.num_parts ||
        !cover.set_num ||
        !cover.set_img_url ||
        !cover.year
      ) {
        return null;
      }
      return cover;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}

export interface ArchiveItem {
  name: string;
  date: Date;
}
