import { useEffect } from "react";
import { ChecklistStorage } from "../../../lib/checklistsStorage";

export default function useRememberVisitedChecklist(
  checklistName: string | undefined
) {
  useEffect(() => {
    if (!checklistName) {
      return;
    }

    const s = new ChecklistStorage();
    s.add(checklistName);
  }, [checklistName]);
}
