import { useNavigate } from "react-router-dom";
import styles from "./Footer.module.css";
import { useChecklistStats } from "../../hooks/useChecklistStats";
import { Status } from "../../../dashboard/models";
import { SaveStatus } from "../../lib/pendingChanges";
import { useChecklistCounts } from "../../contexts/ChecklistContext";

export default function Footer({
  onRefresh,
  saveStatus,
  loadStatus,
}: {
  onRefresh: () => void;
  saveStatus: SaveStatus;
  loadStatus: Status;
}) {
  const nav = useNavigate();

  const state = useChecklistCounts();
  const [stats] = useChecklistStats(state.groups);

  return (
    <div className={styles.footer} data-testid="footer">
      <button type="button" className="back" onClick={() => nav("/")}>
        ↵ Back
      </button>
      {stats && (
        <div className={styles.progress} data-testid="footer-stats">
          <progress
            max={stats.total}
            value={stats.found}
            title={`Found ${stats.found} from ${stats.total} parts`}
          ></progress>
          <span>{stats.percent}%</span>
        </div>
      )}
      {saveStatus === SaveStatus.Saving && <span>💾</span>}
      <button type="button" className={styles.refresh} onClick={onRefresh}>
        <span className={loadStatus === Status.Loading ? styles.loading : ""}>
          {saveStatus === SaveStatus.Failed ? "✕" : "↻"}
        </span>
      </button>
    </div>
  );
}
