import "./dashboard.css";
import { UserChecklists } from "./ui/UserChecklists";
import FinderAndCover from "./ui/FinderAndCover";
import ArchivedList from "./components/archive/ArchivedList";
import { ChecklistCountProvider } from "./contexts/UserChecklistsContext";

export default function Dashboard() {
  return (
    <>
      <div id="dashboard">
        <header></header>
        <main>
          <div className="box intro">
            <h1>LegoInventory App</h1>
            <p>
              Welcome to LegoInventory App, your essential LEGO companion! Ever
              find yourself surrounded by copious amounts LEGO pieces and struggling to identify
              what you need to complete your set? LegoInventory App is here to
              help!
            </p>
            <p>
              Select which LEGO set you're building and start a new
              checklist.
            </p>
            <p>
              You can share checklist URL with your friends and cooperate using
              multiple devices.
            </p>
          </div>
          <div className="box find-set">
            <FinderAndCover />
          </div>
        </main>
        <section className="box user-checklists-container">
          <h2>Continue checklist:</h2>
          <ChecklistCountProvider>
            <UserChecklists></UserChecklists>
            <ArchivedList />
          </ChecklistCountProvider>
        </section>
      </div>
      <div id="main-background"></div>
    </>
  );
}
