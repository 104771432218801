import { useEffect } from "react";
import { createChecklistAPI } from "../../../lib/apiChecklist";
import { Status } from "../models";
import {
  ChecklistItemModel,
  ChecklistDetailsModel,
} from "../store/checklistsListReducer.state";
import { ChecklistsListsAction } from "../store/checklistsListReducer.actions";

export const useChecklistDetailsLoading = (
  items: ChecklistItemModel[],
  dispatch: React.Dispatch<ChecklistsListsAction>
) => {
  useEffect(() => {
    const forLoading = items.filter(
      (item) => item.checklistStatus === Status.None
    );
    if (forLoading.length > 0) {
      const names = forLoading.map((item) => item.name);
      dispatch({ type: "checklists-loading", names: names });

      const api = createChecklistAPI();
      api
        .loadInfo(names)
        .then((data: ChecklistDetailsModel[]) => {
          names.forEach((name) => {
            const details = data.find((d) => d.name === name);
            if (!details) {
              dispatch({ type: "checklists-loading-failed", names: [name] });
            } else {
              dispatch({ type: "checklists-loaded", details: [details] });
            }
          });
        })
        .catch((error) => {
          console.error(error);
          dispatch({ type: "checklists-loading-failed", names: names });
        });
    }
  }, [items, dispatch]);
};
