import { useChecklistCountsDispatch } from "../../contexts/ChecklistContext";
import { usePartsGroupContext } from "../../contexts/PartsGroupContext";

export default function PartsGroupHeader() {
  const { groupName, counts, parts, isCompleted } = usePartsGroupContext();
  const dispatch = useChecklistCountsDispatch();

  const currentCount = counts.reduce((acc, item) => acc + +item.count, 0);
  const quantity = parts.reduce((acc, item) => acc + +item.quantity, 0);
  let imageUrl = "";

  if (parts.length) {
    const notCompleted = parts.filter((item) => {
      const partCount =
        counts.find((x) => x.invPartId === item.invPartId)?.count || 0;
      return +partCount < +item.quantity;
    });

    if (notCompleted.length > 0) {
      imageUrl = notCompleted[0].imageUrl;
    } else {
      imageUrl = parts[0].imageUrl;
    }
  }

  return (
    <div
      className="header"
      onClick={() => {
        dispatch({ type: "toggle-group", groupName: groupName });
      }}
    >
      <div className="parts-image">
        <img src={imageUrl} />
      </div>
      {isCompleted && <div className="completed">✔</div>}
      <h3>{groupName}</h3>
      <div className="info">
        <span className="counter">
          <span className="current">{currentCount}</span>
          &nbsp;of&nbsp;
          <span className="total">{quantity}</span>
        </span>
      </div>
    </div>
  );
}
