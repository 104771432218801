import { useState, useEffect } from "react";

export function useIsIntersecting(ref: React.RefObject<HTMLDivElement>) {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsIntersecting(entry.isIntersecting);
          } else if (entry.boundingClientRect.top < window.scrollY) {
            setIsIntersecting(true);
          }
        });
      },
      {
        root: null,
        rootMargin: "-100px",
        threshold: [0, 0.25, 0.5, 0.75, 1.0],
      }
    );
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref]);

  return isIntersecting;
}
