import { LegoPartCountModel } from "../models";
import { ChecklistDetailsModel } from "../pages/dashboard/store/checklistsListReducer.state";

declare global {
  interface Window {
    Cypress: unknown;
  }
}

export interface ChecklistAPI {
  create(setNumber: string): Promise<{ name: string }>;
  save(
    checklistName: string,
    data: LegoPartCountModel[],
    totalParts: number | null
  ): Promise<LegoPartCountModel[]>;
  load(checklistName: string): Promise<LegoPartCountModel[]>;
  loadInfo(checklistName: string[]): Promise<ChecklistDetailsModel[]>;
}

export function createChecklistAPI(): ChecklistAPI {
  const baseUrl = import.meta.env.VITE_API_CHECKLISTS_URL;

  // if (import.meta.env.VITE_API_CHECKLISTS_USE_GRAPHQL) {
  //   return new ChecklistGraphQLAPI(baseUrl);
  // } else {
  return new ChecklistRESTAPI(baseUrl);
  //}
}

class ChecklistRESTAPI implements ChecklistAPI {
  constructor(private baseUrl: string) {}

  create(setNumber: string) {
    const isTesting = window && window.Cypress ? "/e2etesting" : "";

    return fetch(`${this.baseUrl}checklist/${setNumber}${isTesting}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return response;
      })
      .then((response) => response.json())
      .then((data: { name: string }) => {
        return data;
      });
  }

  loadInfo(checklistName: string[]) {
    const query = checklistName.join(",");

    return fetch(`${this.baseUrl}checklist/progress/${query}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  }

  save(
    checklistName: string,
    data: LegoPartCountModel[],
    totalParts: number | null
  ) {
    const countsData: { invPartId: number; count: number; updated: string }[] =
      data.map((item) => {
        return {
          invPartId: item.invPartId,
          count: item.count,
          updated: item.updated.toISOString(),
        };
      });

    return fetch(`${this.baseUrl}checklist/${checklistName}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        counts: countsData,
        totalParts: totalParts,
      }),
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Failed to save changes");
      }
      return response.json();
    });
  }

  load(checklistName: string): Promise<LegoPartCountModel[]> {
    return fetch(`${this.baseUrl}checklist/${checklistName}`, {
      headers: {
        Accept: "application/json",
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Checklist not found");
      }
      return response.json();
    });
  }
}

class ChecklistGraphQLAPI implements ChecklistAPI {
  constructor(private baseUrl: string) {}

  private callGraphQL<T>(payload: {
    query: string;
    variables: Record<string, unknown>;
  }): Promise<T> {
    return fetch(`${this.baseUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return response;
      })
      .then((response) => response.json())
      .then((json: { data: T } & { errors: { message: string }[] }) => {
        if (json.errors?.length) {
          throw new Error(json.errors[0].message);
        }
        return json.data;
      });
  }

  create(setNumber: string) {
    const isTesting = window && window.Cypress ? "/e2etesting" : "";

    const payload = {
      query: `mutation Create($setNumber: ID!) {
        create(setNumber: $setNumber) {
          name
        }
      }`,
      variables: {
        setNumber: setNumber,
        testMarker: isTesting,
      },
    };
    return this.callGraphQL<{ create: { name: string } }>(payload).then(
      (data) => data.create
    );
  }

  loadInfo(checklistNames: string[]) {
    const payload = {
      query: `query Checklists($names: [ID!]!) {
        checklists(names: $names) {
          name
          created
          progress
          updated
        }
      }`,
      variables: {
        names: checklistNames,
      },
    };
    return this.callGraphQL<{ checklists: ChecklistDetailsModel[] }>(
      payload
    ).then((data) => data.checklists);
  }

  save(
    checklistName: string,
    data: LegoPartCountModel[],
    totalParts: number | null
  ) {
    const countsData: { invPartId: number; count: number; updated: string }[] =
      data.map((item) => {
        return {
          invPartId: item.invPartId,
          count: item.count,
          updated: item.updated.toISOString(),
        };
      });

    const payload = {
      query: `mutation SaveCounts($name: ID!, $counts: [ChecklistCountInput]!, $totalParts: Int) {
        saveCounts(name: $name, counts: $counts, totalParts: $totalParts) {
          invPartId
          count
          updated
        }
      }`,
      variables: {
        name: checklistName,
        counts: countsData,
        totalParts: totalParts,
      },
    };
    return this.callGraphQL<{ saveCounts: LegoPartCountModel[] }>(payload).then(
      (data) => data.saveCounts
    );
  }

  load(checklistName: string): Promise<LegoPartCountModel[]> {
    const payload = {
      query: `query Checklist($name: ID!) {
        checklistCounts(name: $name) {
          invPartId
          count
          updated
        }
      }`,
      variables: {
        name: checklistName,
      },
    };
    return this.callGraphQL<{ checklistCounts: LegoPartCountModel[] }>(
      payload
    ).then((data) => data.checklistCounts);
  }
}
