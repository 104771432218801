import { LegoPartFilterValue } from "../models";

export function isGroupNameMatches(
  groupName: string,
  filterValue: LegoPartFilterValue
) {
  if (filterValue.text?.trim().length > 0) {
    return groupName
      .toLowerCase()
      .includes(filterValue.text.trim().toLowerCase());
  }

  let matchesName = false;
  let matchesSize = false;

  if (filterValue.name.length > 0) {
    matchesName = groupName
      .toLowerCase()
      .includes(filterValue.name.toLowerCase());
  } else {
    matchesName = true;
  }

  const m = groupName.match(/(\d+)\s?x\s?(\d+)(?:\s?x\s?(\d+))?/);
  if (m) {
    if (m.length === 4) {
      const w = parseInt(m[1]);
      const l = parseInt(m[2]);
      const h = parseInt(m[3]);

      if (
        (!filterValue.w || w === filterValue.w) &&
        (!filterValue.l || l === filterValue.l) &&
        (!filterValue.h || h === filterValue.h)
      ) {
        matchesSize = true;
      }
    } else if (m.length === 3) {
      const w = parseInt(m[1]);
      const l = parseInt(m[2]);

      if (
        (!filterValue.w || w === filterValue.w) &&
        (!filterValue.l || l === filterValue.l)
      ) {
        matchesSize = true;
      }
    } else if (m.length === 2) {
      const w = parseInt(m[1]);

      if (!filterValue.w || w === filterValue.w) {
        matchesSize = true;
      }
    }
  }

  return matchesName && matchesSize;
}
