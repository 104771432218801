import { Dispatch, useEffect, useState } from "react";
import {
  PendingChanges,
  SaveStatus,
  SavedInfoModel,
} from "../lib/pendingChanges";
import { ChecklistAction } from "../store/checklistReducer.actions";
import { LegoPartCountModel } from "../../../models";
import { createChecklistAPI } from "../../../lib/apiChecklist";

let saver: PendingChanges | null = null;
let retryCount = 0;

function handleSave(data: SavedInfoModel, dispatch: Dispatch<ChecklistAction>) {
  if (!data.result?.length) {
    return;
  }
  retryCount = 0;
  const onlyForeignChanges = data.result.filter(
    (item) => !data.saved.find((d) => d.invPartId === item.invPartId)
  );
  dispatch({ type: "update-counts", counts: onlyForeignChanges });
}

// function handleFailed(
//   data: LegoPartCountModel[],
//   dispatch: Dispatch<ChecklistAction>
// ) {
//   if (retryCount > 3) {
//     console.error("Failed to save. Giving up.", data);
//     retryCount = 0;
//     return;
//   }
//   console.error("Failed to save. Retrying.", data);
//   dispatch({ type: "update-pending-for-retry", counts: data });
//   retryCount++;
// }

export function useSavePendingCounts(
  checklistName: string | undefined,
  dispatch: Dispatch<ChecklistAction>,
  pending: Map<number, LegoPartCountModel>,
  totalParts: number
) {
  const [status, setStatus] = useState<SaveStatus>(SaveStatus.None);

  useEffect(() => {
    if (checklistName) {
      if (saver) {
        saver.cleanUp();
      }
      saver = new PendingChanges(checklistName, createChecklistAPI());
      saver.saved$.subscribe((data) => handleSave(data, dispatch));
      // saver.failed$.subscribe((data) => handleFailed(data, dispatch));
      saver.status$.subscribe((data) => setStatus(data));
    }
  }, [checklistName, dispatch]);

  useEffect(() => {
    if (!saver) {
      return;
    }
    saver.setTotalParts(totalParts);

    const list = [...pending.entries()].map(([, item]) => {
      return item;
    });
    if (list.length > 0) {
      list.forEach((item) => {
        saver!.addPendingChange(item);
      });
      dispatch({ type: "clear-pending" });
    }
  }, [pending, dispatch]);

  return [status];
}
