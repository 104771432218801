import { LegoPartCountModel, LegoPartModel } from "../../../models";
import { LegoPartFilterValue } from "../models";

export interface ChecklistCountState {
  groups: ChecklistGroupModel[];
  expandedState: boolean;
  completedVisible: boolean;
  filterValue: LegoPartFilterValue | null;
  pending: Map<number, LegoPartCountModel>;
  countUpdatedDate: Date | null;
}

export interface ChecklistGroupModel {
  groupName: string;
  parts: LegoPartChecklistModel[];
  counts: LegoPartCountModel[];
  isExpanded: boolean;
  isVisible: boolean;
}

export type LegoPartChecklistModel = LegoPartModel & {
  title: string;
  isVisible: boolean;
};

export function createInitialState(): ChecklistCountState {
  return {
    groups: [],
    expandedState: true,
    completedVisible: true,
    filterValue: null,
    pending: new Map<number, LegoPartCountModel>(),
    countUpdatedDate: null,
  };
}
