import { useEffect, useState } from "react";
import "./contextMenu.css";

export function ContextMenuWithToggle({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    let invokedByToggle: EventTarget | null = null;

    function handleClick(e: MouseEvent) {
      if (!invokedByToggle) {
        invokedByToggle = e.target;
      }

      const target = e.target as HTMLElement;
      const ignoredParents = [".menu-toggle", ".menu"];

      const isOtherMenuToggle =
        target.closest(".menu-toggle") && e.target !== invokedByToggle;

      const shouldClose =
        isOtherMenuToggle ||
        ignoredParents.every((selector) => !target.closest(selector));

      if (shouldClose) {
        setMenuOpen(false);
        cleanup();
      }
    }

    function cleanup() {
      document.removeEventListener("click", () => handleClick);
    }

    if (menuOpen) {
      document.addEventListener("click", (e: MouseEvent) => handleClick(e));
      return () => cleanup();
    }
  }, [menuOpen]);

  function openMenu(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    const closeElementListener = (e.target as HTMLElement).parentElement!
      .parentElement!;
    closeElementListener.addEventListener(
      "close-menu",
      () => setMenuOpen(false),
      { once: true }
    );
    setMenuOpen(true);
  }

  return (
    <>
      <div className="menu-toggle" onClick={(e) => openMenu(e)}>
        <div>&#8943;</div>
      </div>
      <ContextMenu isOpen={menuOpen}>{children}</ContextMenu>
    </>
  );
}

export function ContextMenu({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: React.ReactNode;
}) {
  return (
    <div className={"menu" + (isOpen ? " open" : "")}>
      <ul>{children}</ul>
    </div>
  );
}
