import { useState, useEffect } from "react";
import { ChecklistGroupModel } from "../store/checklistReducer.state";

export interface ChecklistStats {
  total: number;
  found: number;
  percent: number;
}

export const useChecklistStats = (groups: ChecklistGroupModel[]) => {
  const [stats, setStats] = useState<ChecklistStats | null>(null);

  useEffect(() => {
    const newStats: ChecklistStats = {
      total: 0,
      found: 0,
      percent: 0,
    };
    groups.forEach((group) => {
      newStats.total += group.parts.reduce(
        (acc, part) => acc + part.quantity,
        0
      );
      newStats.found += group.counts.reduce(
        (acc, count) => acc + count.count,
        0
      );
    });
    if (newStats.total > 0) {
      newStats.percent = +((newStats.found / newStats.total) * 100).toFixed(1);
    }
    setStats(newStats);
  }, [groups]);

  return [stats];
};
