export interface CoverModel {
  set_num: string;
  name: string;
  year: number;
  num_parts: number;
  set_img_url: string;
}

export enum Status {
  None,
  Loading,
  Error,
  Loaded,
}
