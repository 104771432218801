import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

export default function useCookieConsent() {
  useEffect(() => {
    CookieConsent.run({
      onConsent: function ({ cookie }) {
        if (cookie.categories.includes("analytics")) {
          initSentry();
        }
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {},
      },
      guiOptions: {
        consentModal: {
          position: "bottom left",
        },
      },
      language: {
        default: "en",
        translations: {
          en: {
            consentModal: {
              title: "We use cookies",
              description:
                "We use cookies to ensure you get the best experience on our website.",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage",
            },
            preferencesModal: {
              title: "Manage cookie preferences",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Accept current selection",
              closeIconLabel: "Close modal",
              sections: [
                {
                  title: "Strictly Necessary cookies",
                  description:
                    "These cookies are essential for the proper functioning of the website and cannot be disabled.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Performance and site maintenance cookies",
                  description:
                    "These cookies allow us to measure and improve the performance of our site and detect errors to improve your experience.",
                  linkedCategory: "analytics",
                },
              ],
            },
          },
        },
      },
    });
  }, []);
}

function initSentry() {
  Sentry.init({
    dsn: "https://c1efc1f3e1ccb1cef5afe5d891061ee7@o4507304956329984.ingest.de.sentry.io/4507304957837392",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      import.meta.env.VITE_API_SETS_URL,
      import.meta.env.VITE_API_CHECKLISTS_URL,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
