import { Status } from "../../dashboard/models";

export default function LoadingStatus({
  setDetailsStatus,
  checklistStatus,
  onTryChecklistAgain,
}: {
  setDetailsStatus: Status;
  checklistStatus: Status;
  onTryChecklistAgain: () => void;
}) {
  if (setDetailsStatus === Status.Loaded && checklistStatus === Status.Error) {
    return (
      <>
        <div className="list-status">
          <p>
            Could not load checklist data.
            <button className="btn-secondary" onClick={onTryChecklistAgain}>
              Try again?
            </button>
          </p>
        </div>
        <div className="list-status-overlay"></div>
      </>
    );
  }

  if (setDetailsStatus === Status.Error) {
    return (
      <>
        <div className="list-status">
          Sorry, couldn't load this checklist due to errors.
        </div>
        <div className="list-status-overlay"></div>
      </>
    );
  }

  if (setDetailsStatus === Status.Loading) {
    return (
      <>
        <div className="list-status">Loading...</div>
        <div className="list-status-overlay"></div>
      </>
    );
  }

  if (checklistStatus === Status.Loading) {
    return (
      <>
        <div className="list-status">Loading checklist...</div>
        <div className="list-status-overlay"></div>
      </>
    );
  }

  return null;
}